import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import objectFitImages from 'object-fit-images';

objectFitImages('.obj-fit-img');

document.querySelectorAll('.g-nav-open').forEach(gnav => {
	gnav.addEventListener('click', function (e) {
		this.classList.toggle('active');
		document.getElementById('g-nav').classList.toggle('panelactive');
		document.body.classList.toggle('no-scrol');
	}.bind(gnav))
});

document.querySelectorAll('#g-nav a').forEach(gnavLink => {
	gnavLink.addEventListener('click', () => {
		document.querySelectorAll('.g-nav-open').forEach((gnav) => {
			gnav.classList.remove('active');
		})
		document.getElementById('g-nav').classList.remove('panelactive');
		document.body.classList.remove('no-scrol');
	})
});

document.querySelectorAll('.js_h').forEach(jsh => {
	['mouseenter', 'touchstart'].forEach(event => {
		jsh.addEventListener(event, () => {
			jsh.classList.add('hover');
		})
	});
	['mouseleave', 'touchend'].forEach(event => {
		jsh.addEventListener(event, () => {
			jsh.classList.remove('hover');
		})
	});
});


$(function () {
    // スムーススクロール ※ページ外からのアンカーリンクでも効くように
    var headerHeight = $('.header-navbar').outerHeight()　+ 20;
	var urlHash = location.hash;
	if(urlHash) {
		$('body,html').stop().scrollTop(0);
		setTimeout(function(){
			var target = $(urlHash);
			var position = target.offset().top - headerHeight;
			$('body,html').stop().animate({scrollTop:position}, 500);
		}, 100);
	}
	$('a[href^="#"]').click(function() {
        if ($(this).attr('id') === "fab-bot"){
            return false;
        }
		var href= $(this).attr("href");
		href = ('#' === href) ? '#pagetop': href;
		var target = $(href);
		var position = target.offset().top - headerHeight;
		$('body,html').stop().animate({scrollTop:position}, 500);
	});
});